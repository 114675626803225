/*****************
	Utility
 *****************/
/*
  Font Size Utility
*/
$step: 1;
@for $i from 0 through 100 {
	.fs#{$i * $step} {
		font-size: #{$i * $step}px !important;
	}
}
@media(min-width: 768px) {
	@for $i from 0 through 100 {
		.sm-fs#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}
@media(min-width: 992px) {
	@for $i from 0 through 100 {
		.md-fs#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}
@media(min-width: 1200px) {
	@for $i from 0 through 100 {
		.lg-fs#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}

/*
  Spacing Utility
*/
$step: 1;
@for $i from 0 through 20 {
	.m#{$i * $step} {
		margin: #{$i * $step}px !important;
	}
	.mt#{$i * $step} {
		margin-top: #{$i * $step}px !important;
	}
	.mr#{$i * $step} {
		margin-right: #{$i * $step}px !important;
	}
	.mb#{$i * $step} {
		margin-bottom: #{$i * $step}px !important;
	}
	.ml#{$i * $step} {
		margin-left: #{$i * $step}px !important;
	}
	.p#{$i * $step} {
		padding: #{$i * $step}px !important;
	}
	.pt#{$i * $step} {
		padding-top: #{$i * $step}px !important;
	}
	.pr#{$i * $step} {
		padding-right: #{$i * $step}px !important;
	}
	.pb#{$i * $step} {
		padding-bottom: #{$i * $step}px !important;
	}
	.pl#{$i * $step} {
		padding-left: #{$i * $step}px !important;
	}
}
$step: 5;
@for $i from 5 through 40 {
	.m#{$i * $step} {
		margin: #{$i * $step}px !important;
	}
	.mt#{$i * $step} {
		margin-top: #{$i * $step}px !important;
	}
	.mr#{$i * $step} {
		margin-right: #{$i * $step}px !important;
	}
	.mb#{$i * $step} {
		margin-bottom: #{$i * $step}px !important;
	}
	.ml#{$i * $step} {
		margin-left: #{$i * $step}px !important;
	}
	.p#{$i * $step} {
		padding: #{$i * $step}px !important;
	}
	.pt#{$i * $step} {
		padding-top: #{$i * $step}px !important;
	}
	.pr#{$i * $step} {
		padding-right: #{$i * $step}px !important;
	}
	.pb#{$i * $step} {
		padding-bottom: #{$i * $step}px !important;
	}
	.pl#{$i * $step} {
		padding-left: #{$i * $step}px !important;
	}
}

$limit: 40;
@media(min-width: 768px) {
	$step: 1;
	@for $i from 0 through $limit {
		.sm-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.sm-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.sm-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.sm-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.sm-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.sm-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.sm-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.sm-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.sm-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.sm-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit {
		.sm-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.sm-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.sm-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.sm-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.sm-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.sm-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.sm-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.sm-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.sm-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.sm-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

@media(min-width: 992px) {
	$step: 1;
	@for $i from 0 through $limit {
		.md-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.md-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.md-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.md-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.md-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.md-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.md-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.md-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.md-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.md-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit {
		.md-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.md-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.md-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.md-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.md-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.md-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.md-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.md-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.md-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.md-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

@media(min-width: 1200px) {
	$step: 1;
	@for $i from 0 through $limit {
		.lg-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.lg-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.lg-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.lg-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.lg-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.lg-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.lg-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.lg-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.lg-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.lg-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit {
		.lg-m#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.lg-mt#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.lg-mr#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.lg-mb#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.lg-ml#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.lg-p#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.lg-pt#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.lg-pr#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.lg-pb#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.lg-pl#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

/*
  Text Utility
*/
.text-left		{ text-align: left !important; }
.text-center	{ text-align: center !important; }
.text-right		{ text-align: right !important; }
@media(min-width: 768px) {
	.text-sm-left		{ text-align: left !important; }
	.text-sm-center		{ text-align: center !important; }
	.text-sm-right		{ text-align: right !important; }
}
@media(min-width: 992px) {
	.text-md-left		{ text-align: left !important; }
	.text-md-center		{ text-align: center !important; }
	.text-md-right		{ text-align: right !important; }
}
@media(min-width: 1200px) {
	.text-lg-left		{ text-align: left !important; }
	.text-lg-center		{ text-align: center !important; }
	.text-lg-right		{ text-align: right !important; }
}

.underline { text-decoration: underline; }
.bold {font-weight: bold;}

/*
  Float Utility
*/
.float-left		{ float: left !important; }
.float-right		{ float: right !important; }
@media(min-width: 768px) {
	.float-sm-left		{ float: left !important; }
	.float-sm-right		{ float: right !important; }
}
@media(min-width: 992px) {
	.float-md-left		{ float: left !important; }
	.float-md-right		{ float: right !important; }
}
@media(min-width: 1200px) {
	.float-lg-left		{ float: left !important; }
	.float-lg-right		{ float: right !important; }
}
.m-auto {
	margin: 0 auto;
}

/*
  Display Utility
*/
.d-inline-block {
	display: inline-block;
}

.flexbox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
