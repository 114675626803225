/* Variable
------------------------------------------------------------ */
$site-width: 720px;
$main-border-width: 5px;

/* Global
------------------------------------------------------------ */
body {
	font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
	margin: 0;
	//background-color: #6fea11;
	background-color: #06abe6;

	background-image: url(../img/estimate/bg.jpg);
	background-size: 100% auto;
	background-repeat: no-repeat;
}
ul {
	padding: 0;
	list-style-type: none;
}
ol {
	li {
		font-size: 14px;
		line-height: 1.7;
	}
}
img {
	max-width: 100%;
	height: auto;
}
a {
	text-decoration: none;
}
p {
	line-height: 1.7;
	font-size: 14px;
	margin-top: 0;
}
table {
	border-collapse: collapse;
}

/* Utility
------------------------------------------------------------ */
.ff-marugo {
	font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
}

/* Layout
------------------------------------------------------------ */
.wrap {
	//max-width: $site-width;
	width: $site-width;
	//margin: 0 auto;
	margin: 0 auto 150px;
}

header {
	padding-top: 30px;
	.flexbox {
		// display: flex;
		// justify-content: space-between;
		.wrap-logo {
			img {
				max-width: 330px;
			}
		}
		ul.nav {
			li {
				display: inline-block;
				padding: 0px 15px;
				border-left: 1px solid #222;
				a {
					color: #222;
				}
				&:last-child {
					border-right: 1px solid #222;
				}
			}
		}
	}

	.title {
		color: #fff;
		font-size: 35px;
		display: flex;
		align-items: center;
		font-weight: bold;
		&:before, &:after {
			content: "";
			flex-grow: 1;
			height: 1px;
			background: #fff;
			display: block;
		}
		&:before {
			margin-right: .4em;
		}
		&:after {
			margin-left: .4em;
		}
	}

}

.container {
	min-height: 60vh;
}


/* Main
------------------------------------------------------------ */
.main {
	border-radius: 20px;
	border: $main-border-width solid rgba(#555, .6);
	width: calc(100% - #{$main-border-width} * 2);
	background-color: rgba(#fff, .8);
	position: relative;
	margin: 80px auto 30px;
	padding-top: 80px;
	padding-bottom: 40px;
	.title-result {
		position: absolute;
		display: block;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: -40px;
		max-width: 560px;
	}
	.inner {
		padding: 0 30px;
		.pers {
			max-width: 550px;
			margin: 0 auto 30px;
		}
		h3 {
			font-weight: normal;
			font-size: 16px;
			margin-bottom: 20px;
		}
	}
	.wrap-btn {
		text-align: center;
		margin-bottom: 40px;
		a {
			img {
				max-height: 70px;
				width: auto;
			}
			&:first-child {
				margin-right: 15px;
			}
		}
	}
	.wrap-info {
		max-width: 85%;
		margin: 0 auto 50px;
		p {
			font-size: 18px;
		}
	}
	.logo-lixil {
		max-width: 250px;
	}
}

.table-details {
	text-align: center;
	margin-bottom: 50px;
	table {
		width: 100%;
		margin: 0 auto;
		tbody {
			tr {
				th, td {
					vertical-align: top;
					font-size: 15px;
					line-height: 1.8;
					padding: 15px 10px 50px;
					border-bottom: 1px solid #ccc;
				}
				th {
					text-align: right;
					// padding-right: 50px;
				}
				td {
					text-align: left;
					padding-bottom: 20px;
				}
                &:last-child {
                    th, td {
                        border-bottom: none;
                    }
                }
			}
		}
	}
}

.table-price {
	margin-bottom: 20px;
	table {
		width: 100%;
		border-top: 1px solid #222;
		border-bottom: 1px solid #222;
		colgroup {
			.col1 {
				width: 70%;
			}
			.col2 {
				width: 5%;
			}
		}
		tbody {
			tr {
				th, td {
					vertical-align: top;
					font-size: 18px;
					padding: 5px 10px;
				}
				th {
					text-align: left;
					font-weight: normal;
				}
				td {
					text-align: right;
				}
			}
			tr.item {
				th, td {
					border-bottom: 1px solid #aaa;
				}
			}
			tr.sum {
				th:nth-child(1),
				th:nth-child(2), td {
					border-bottom: 1px solid #aaa;
				}
			}
			tr.tax {
				th:first-child {
					font-size: 14px;
				}
			}
		}
	}
}

/* Footer
------------------------------------------------------------ */
footer {
	background-color: #2375AD;
	color: #fff;
	padding: 5px;
	.inner {
		max-width: $site-width;
		margin: 0 auto;
		align-items: center;
		.link {
			a {
				font-size: 10px;
				color: #fff;
				text-decoration: underline;
				position: relative;
				top: -2px;
			}
		}
		.copyright {
			font-size: 10px;
		}
	}
}
