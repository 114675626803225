/* Variable
------------------------------------------------------------ */
$site-width: 1200px;

/* Global
------------------------------------------------------------ */
body {
	font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
	margin: 0;
}
ul {
	padding: 0;
	list-style-type: none;
}
ol {
	li {
		font-size: 14px;
		line-height: 1.7;
	}
}
img {
	max-width: 100%;
	height: auto;
}
a {
	text-decoration: none;
}
p {
	line-height: 1.7;
	font-size: 14px;
	margin-top: 0;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}
