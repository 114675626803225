@charset "UTF-8";
/* Variable
------------------------------------------------------------ */
/* Global
------------------------------------------------------------ */
body {
  font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
  margin: 0;
  background-color: #06abe6;
  background-image: url(../img/estimate/bg.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat; }

ul {
  padding: 0;
  list-style-type: none; }

ol li {
  font-size: 14px;
  line-height: 1.7; }

img {
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none; }

p {
  line-height: 1.7;
  font-size: 14px;
  margin-top: 0; }

table {
  border-collapse: collapse; }

/* Utility
------------------------------------------------------------ */
.ff-marugo {
  font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO"; }

/* Layout
------------------------------------------------------------ */
.wrap {
  width: 720px;
  margin: 0 auto 150px; }

header {
  padding-top: 30px; }
  header .flexbox .wrap-logo img {
    max-width: 330px; }
  header .flexbox ul.nav li {
    display: inline-block;
    padding: 0px 15px;
    border-left: 1px solid #222; }
    header .flexbox ul.nav li a {
      color: #222; }
    header .flexbox ul.nav li:last-child {
      border-right: 1px solid #222; }
  header .title {
    color: #fff;
    font-size: 35px;
    display: flex;
    align-items: center;
    font-weight: bold; }
    header .title:before, header .title:after {
      content: "";
      flex-grow: 1;
      height: 1px;
      background: #fff;
      display: block; }
    header .title:before {
      margin-right: .4em; }
    header .title:after {
      margin-left: .4em; }

.container {
  min-height: 60vh; }

/* Main
------------------------------------------------------------ */
.main {
  border-radius: 20px;
  border: 5px solid rgba(85, 85, 85, 0.6);
  width: calc(100% - 5px * 2);
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  margin: 80px auto 30px;
  padding-top: 80px;
  padding-bottom: 40px; }
  .main .title-result {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -40px;
    max-width: 560px; }
  .main .inner {
    padding: 0 30px; }
    .main .inner .pers {
      max-width: 550px;
      margin: 0 auto 30px; }
    .main .inner h3 {
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 20px; }
  .main .wrap-btn {
    text-align: center;
    margin-bottom: 40px; }
    .main .wrap-btn a img {
      max-height: 70px;
      width: auto; }
    .main .wrap-btn a:first-child {
      margin-right: 15px; }
  .main .wrap-info {
    max-width: 85%;
    margin: 0 auto 50px; }
    .main .wrap-info p {
      font-size: 18px; }
  .main .logo-lixil {
    max-width: 250px; }

.table-details {
  text-align: center;
  margin-bottom: 50px; }
  .table-details table {
    width: 100%;
    margin: 0 auto; }
    .table-details table tbody tr th, .table-details table tbody tr td {
      vertical-align: top;
      font-size: 15px;
      line-height: 1.8;
      padding: 15px 10px 50px;
      border-bottom: 1px solid #ccc; }
    .table-details table tbody tr th {
      text-align: right; }
    .table-details table tbody tr td {
      text-align: left;
      padding-bottom: 20px; }
    .table-details table tbody tr:last-child th, .table-details table tbody tr:last-child td {
      border-bottom: none; }

.table-price {
  margin-bottom: 20px; }
  .table-price table {
    width: 100%;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222; }
    .table-price table colgroup .col1 {
      width: 70%; }
    .table-price table colgroup .col2 {
      width: 5%; }
    .table-price table tbody tr th, .table-price table tbody tr td {
      vertical-align: top;
      font-size: 18px;
      padding: 5px 10px; }
    .table-price table tbody tr th {
      text-align: left;
      font-weight: normal; }
    .table-price table tbody tr td {
      text-align: right; }
    .table-price table tbody tr.item th, .table-price table tbody tr.item td {
      border-bottom: 1px solid #aaa; }
    .table-price table tbody tr.sum th:nth-child(1),
    .table-price table tbody tr.sum th:nth-child(2), .table-price table tbody tr.sum td {
      border-bottom: 1px solid #aaa; }
    .table-price table tbody tr.tax th:first-child {
      font-size: 14px; }

/* Footer
------------------------------------------------------------ */
footer {
  background-color: #2375AD;
  color: #fff;
  padding: 5px; }
  footer .inner {
    max-width: 720px;
    margin: 0 auto;
    align-items: center; }
    footer .inner .link a {
      font-size: 10px;
      color: #fff;
      text-decoration: underline;
      position: relative;
      top: -2px; }
    footer .inner .copyright {
      font-size: 10px; }
