@import "normalize";
@import "global";
@import "canvas";

$action-panel-height: 110px;
$action-nav-height: 56px;
$action-nav-width: 120px;
$site-width: 720px;
$action-box-width: 710px;
$action-box-height: 560px;
$main-border-width: 5px;


body.page-estimate {
    height: 100%;
    background-image: url("/static/img/park/bk.png");
    background-repeat: repeat-x;
    background-position: top center;
    background-size: auto 100%;
    background-attachment: fixed;
}


.area {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.page-estimate header {
    width: 720px;
}

.page-estimate .main {
    padding-bottom: 0px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    .title {
        position: absolute;
        display: block;
        margin: 0 auto;
        left: -5px;
        right: 0;
        top: -40px;
        width: 720px;
        height: 63.5px;
        background: {
            size: auto 100%;
            position: center;
            repeat: no-repeat;
        }
        &.title_course {
            background: {
                image: url("/static/sozai/course/title.png");
            }
        }
        &.title_base {
            background: {
                image: url("/static/sozai/base/title.png");
            }
        }
        &.title_parking {
            background: {
                image: url("/static/sozai/parking/title.png");
            }
        }
        &.title_mon {
            background: {
                image: url("/static/sozai/mon/title.png");
            }
        }
        &.title_hei {
            background: {
                image: url("/static/sozai/hei/title.png");
            }
        }
        &.title_result {
            background: {
                image: url("/static/img/estimate/title-result.png");
            }
        }
    }
          // <img 
          //   src={`/static/sozai/${page}/title.png`}
          //   className="title" />
    .inner.fixed {
        height: $action-box-height;
        box-sizing: border-box;
    }
    &.base {
        padding-top: 30px;
        .inner.fixed {
            height: $action-box-height;
            box-sizing: border-box;
        }
    }
    &.course {
        border: none;
        background-color: transparent;
        padding-top: 30px;
        border-radius: 20px;
        border: $main-border-width solid rgba(#555, 0);
        width: 100%;
        background-color: rgba(#fff, 0);
        position: relative;
        margin: 80px auto 30px;
        //padding-top: 80px;
        .inner.fixed {
            box-sizing: border-box;
            height: $action-box-height + $action-panel-height;
        }
    }
}

$choice-animation-sec: 0.6s;

.animated-choice {
    width: 100%;
    height: 480px;
    overflow:: hidden;
    position: absolute;
    top: 100px;
    left: 0px;
    box-sizing: border-box;
    .choice-area {
        height: 360px;
        width: 10000px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        padding-left: 180px;
        @for $i from 0 through 20 {
            &.selected#{$i} {
                left: calc(-360px * #{$i});
                transition: all $choice-animation-sec ease-out;
            }
        }
    }
    .choice-ctrl {
        position: absolute;
        left: 0px;
        top: 360px;
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 200px;
        margin-left: 255px;
        button {
            cursor: pointer;
        }
        button.disabled {
            opacity: 0.5;
        }
    }
    .choice-action {
        position: absolute;
        top: 410px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        button {
            cursor: pointer;
        }
        button.disabled {
            opacity: 0.5;
        }

    }
    .choiceWrap {
        width: 200px;
        max-width: none;
        margin-right: 160px;
        transition: all $choice-animation-sec ease-out;
        &.selected {
            width: 355px;
            max-width: none;
            margin-right: 100px;
        }
    }
    img.choice {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        transition: all $choice-animation-sec ease-out;
    }
    // img.choice.selected {
    //     width: 355px;
    //     max-width: none;
    //     margin-right: 160px;
    //     margin-right: 100px;
    //     transition: all $choice-animation-sec ease-out;
    // }
}

        .action-button {
            height: 61.5px;
            width: 156px;
            background: {
                size: 156px auto;
            }
            cursor: pointer;
            &.return {
                background: {
                    image: url("/static/sozai/return.png");
                }
            }
            &.no {
                background: {
                    image: url("/static/sozai/no.png");
                }
            }
            &.choice {
                background: {
                    image: url("/static/sozai/choice.png");
                }
            }
        }
#action-panel {
    height: $action-panel-height;
    width: 100%;
    background: #C4F0AC;
    position: fixed;
    bottom: 0;
    .inner {
        width: $site-width;
        margin: 0 auto;
        li.nav {
            display: inline-block;
            margin-top: 16px;
            margin-left: 10px;
            &:first-child {
                margin-left: 32px;
            }
            height: $action-nav-height;
            width: $action-nav-width;
            background-image: url("/static/sozai/footer_nav/109_nav1.png");
            background-position: 0px 56px;
            background-size: 120px 110px;
            &.nav-base {
                background-image: url("/static/sozai/footer_nav/109_nav1.png");
                &.active {
                    background-position: 0px 2px;
                }
            }
            &.nav-hei {
                background-image: url("/static/sozai/footer_nav/109_hei.png");
                &.active {
                    background-position: 0px 2px;
                }
            }
            &.nav-mon {
                background-image: url("/static/sozai/footer_nav/109_mon.png");
                &.active {
                    background-position: 0px 2px;
                }
            }
            &.nav-parking {
                background-image: url("/static/sozai/footer_nav/109_parking.png");
                &.active {
                    background-position: 0px 2px;
                }
            }
            &.nav-estimate {
                background-image: url("/static/sozai/footer_nav/109_nav5.png");
                &.active {
                    background-position: 0px 2px;
                }
            }
        }

    }
}

.course-cards {
    .course-card {
        width: 312px;
        height: 332px;
        background: transparent;
        float: left;
        display: block;
        margin-left: 16px;
        margin-bottom: 15px;
        background-size: 312px 332px;
        position: relative;
        .button {
            position: absolute;
            bottom: 20px;
            left: 0px;
            height: 52.5px;
            width: 100%;
            background: {
                image: url("/static/sozai/course/btn/521_105_start.png");
                size: auto 100%;
                repeat: no-repeat;
                position: center;
            }
            cursor: pointer;
            &.park_1 {
                background: {
                    image: url("/static/sozai/course/btn/246_106_1dai.png");
                    position: 30px;
                }
                width: 50%;
            }
            &.park_2 {
                background: {
                    image: url("/static/sozai/course/btn/246_106_2dai.png");
                    position: 0px;
                }
                margin-left: 50%;
                width: 50%;
            }
            &.mon_o {
                background: {
                    image: url("/static/sozai/course/btn/247_106_open.png");
                    position: 30px;
                }
                width: 50%;
            }
            &.mon_c {
                background: {
                    image: url("/static/sozai/course/btn/247_106_closeed.png");
                    position: 0px;
                }
                margin-left: 50%;
                width: 50%;
            }
        }
                    // src="/static/sozai/course/btn/246_106_1dai.png"
                    // src="/static/sozai/course/btn/246_106_2dai.png"
        // .buttons {
        //     position: absolute;
        //     bottom: 25px;
        //     left: 20px;
        //     display: flex;
        //     img {
        //         width: calc(246px/2);
        //         height: calc(106px/2);
        //         margin-right: 20px;
        //     }
        // }
        &.card01 {
            background-image: url("/static/sozai/course/panel/623_665_panel1.png");
        }
        &.card02 {
            background-image: url("/static/sozai/course/panel/624_665_panel2.png");
        }
        &.card03 {
            background-image: url("/static/sozai/course/panel/623_665_panel3.png");
        }
        &.card04 {
            background-image: url("/static/sozai/course/panel/624_665_panel4.png");
        }
    }
}

.course-selector {
    display: block;
    margin: 0px;
    position: absolute;
    top: 110px;
    left: 0px;
    width: 720px;
    li {
        display: block;
        text-align: center;
        margin-top: 30px;
    }
}
.choice-selector-ctrl {
        position: absolute;
        top: 510px;
        left: 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        button {
            cursor: pointer;
        }
        button.disabled {
            opacity: 0.5;
        }
}

body.top {
    background: #FFFFE4;
    width: 100%;
    height: 100%;
}

.top .wrap,
.top #only-first {
    display: none;
    position: relative;
    margin: auto;
    width: 720px;
    .haikei {
        position: absolute;
        top: 0px;
        z-index: 10;
        width: 720px;
    }
    .parts {
        position: absolute;
        top: 0px;
        z-index: 20;
        img {
            width: 100%;
            height: 100%;
        }
        .first {
            position: absolute;
            top: 0px;
            z-index: 11;
            width: 607px;
        }

        &.start.study {
            top: 855px;
            left: 48px;
            width: calc(620px*0.47);
        }
        &.start.simulation {
            top: 855px;
            right: 53px;
            width: calc(620px*0.47);
        }
        &.link {
            width: calc(263px*0.47);
            top: 510px;
            right: 50px;
        }
        &.const {
            width: calc(263px*0.47);
            top: 516px;
            left: 50px;
        }
        &.start.study_linkarea {
            top: 772px;
            left: 40px;
            width: calc(660px*0.47);
            height: 124px;
            display: block;
        }
        &.start.study_linkarea a {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
        }
        &.start.simulation_linkarea {
            top: 772px;
            right: 40px;
            width: calc(660px*0.47);
            height: 124px;
            display: block;
        }
        &.start.simulation_linkarea a {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
        }
        &.mazukoko {
            top: 715px;
            &.mazukoko3 {
                right: 42px;
                width: calc(533px*0.47);
            }
            &.mazukoko2 {
                //right: calc(303px-96);
                right: 303px;
                width: calc(379px*0.47);
            }
            &.mazukoko1 {
                left: 40px;
                width: calc(405px*0.47);
            }
        }
    }
}

.inner.fixed .sub_title {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 720px;
    height: 100px;
    margin: 0px;
    background-position: top center;
    background-repeat: no-repeat;
    &.sub_title_sj {
        background-image: url("/static/sozai/base/sub_title_sj.png");
        background-size: auto calc(106px/2);
    }
    &.sub_title_ti {
        background-image: url("/static/sozai/base/sub_title_ti.png");
        background-size: auto 25px;
    }
    &.sub_title_dk {
        background-image: url("/static/sozai/base/sub_title_dk.png");
        background-size: auto calc(115px/2);
    }
    &.sub_title_parking_1p {
        background-image: url("/static/sozai/parking/sub_title_parking_1p.png");
        background-size: auto 25px;
    }
    &.sub_title_parking_2p {
        background-image: url("/static/sozai/parking/sub_title_parking_2p.png");
        background-size: auto 25px;
    }
    &.sub_title_MCL {
        background-image: url("/static/sozai/mon/sub_title_MCL.png");
        background-size: auto 25px;
    }
    &.sub_title_MOP {
        background-image: url("/static/sozai/mon/sub_title_MOP.png");
        background-size: auto 25px;
    }
    &.sub_title_sk {
        background-image: url("/static/sozai/base/sub_title_sk.png");
        background-size: auto calc(115px/2);
        cursor: pointer;
    }
}

.logo-lixil {
    margin-bottom: 40px;
}

.modal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(0,0,0, 0.2);
    img {
        margin: 20vh auto 0px auto;
        width: 515px;
        display: block;
    }
}

#action-panel {
    .inner {
        li.nav {
            margin-top : 10px;
        }
        ul {
            margin: 12px 0 0;
        }
        p.copyright {
            text-align: center;
            font-size: 70%;
            color: #666;
        }
    }
}

#top-copyright {
    line-height: 1.7;
    margin-top: 0;
    top: 930px;
    left: 0px;
    position: absolute;
    z-index: 1000;
    width: 100%;
    text-align: center;
    color: #666;
    font-size: 70%;
}

.wrap-info.caution p {
    font-size: 80% !important;
    margin: 0;
    padding: 0;
}
.top #only-first {
    display: block;
    z-index: 100;
}
.top .wrap {
    display: block;
}
    #top_pc {
        display: none;
    }
    #top_sp {
        display: none;
    }
@media screen and (max-width:999px) {
    #top_sp {
        display: block;
    }
}


@media screen and (min-width:1000px) {
    body.top {
        margin: 0;
        padding: 0;
        background: #ffffe4 url("/static/sozai/top_pc/background.jpg") center !important;
    }

    #top_pc {
        width: 1335px;
        margin: 0 auto;
        min-height: 100vh;
        position: relative;
        display: block;
    }
    #top_pc img {
        position: absolute;
    }
    #top_pc .logo {
      top: 10px;
      left: 391px;
    }
    #top_pc .niwa {
      top: 200px;
      left:404px;
    }
    #top_pc .btn_hajimete {
      top: 640px;
      left:603px;
    }
    #top_pc .ttl_about {
      top: 143px;
      left: 0;
    }
    #top_pc .btn_about_1 {
      top: 216px;
      left: 0;
    }
    #top_pc .btn_about_2 {
      top: 280px;
      left: 0;
    }
    #top_pc .btn_about_3 {
      top: 344px;
      left: 0;
    }
    #top_pc .btn_link {
      top: 550px;
      left: 0;
    }
    #only-first2 img {
        position: absolute;
        top: 20px;
        left: 342px;
        z-index: 100;
        opacity: 0.95;
    }
    #top_pc .btn_study {
        top: 216px;
        left: 1082px;
    }
    #top_pc .btn_simulation {
        top: 465px;
        left: 1082px;
    }
    #top_pc .btn_const {
        top: 430px;
        left: 0;
    }
    #top_pc .notice {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 700px;
    }
    #top_pc .copyright {
        position: fixed;
        bottom: 0;
        width: 1335px;
        height: 25px;
        background-color: #ffab2c;
        padding-top: 3px;
    }
}
