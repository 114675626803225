@charset "UTF-8";
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* Variable
------------------------------------------------------------ */
/* Global
------------------------------------------------------------ */
body {
  font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
  margin: 0; }

ul {
  padding: 0;
  list-style-type: none; }

ol li {
  font-size: 14px;
  line-height: 1.7; }

img {
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none; }

p {
  line-height: 1.7;
  font-size: 14px;
  margin-top: 0; }

button {
  border: none;
  background: none;
  cursor: pointer; }

body.page-estimate {
  height: 100%;
  background-image: url("/static/img/park/bk.png");
  background-repeat: repeat-x;
  background-position: top center;
  background-size: auto 100%;
  background-attachment: fixed; }

.area {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px; }

.page-estimate header {
  width: 720px; }

.page-estimate .main {
  padding-bottom: 0px;
  width: 700px;
  margin-left: auto;
  margin-right: auto; }
  .page-estimate .main .title {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: -5px;
    right: 0;
    top: -40px;
    width: 720px;
    height: 63.5px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat; }
    .page-estimate .main .title.title_course {
      background-image: url("/static/sozai/course/title.png"); }
    .page-estimate .main .title.title_base {
      background-image: url("/static/sozai/base/title.png"); }
    .page-estimate .main .title.title_parking {
      background-image: url("/static/sozai/parking/title.png"); }
    .page-estimate .main .title.title_mon {
      background-image: url("/static/sozai/mon/title.png"); }
    .page-estimate .main .title.title_hei {
      background-image: url("/static/sozai/hei/title.png"); }
    .page-estimate .main .title.title_result {
      background-image: url("/static/img/estimate/title-result.png"); }
  .page-estimate .main .inner.fixed {
    height: 560px;
    box-sizing: border-box; }
  .page-estimate .main.base {
    padding-top: 30px; }
    .page-estimate .main.base .inner.fixed {
      height: 560px;
      box-sizing: border-box; }
  .page-estimate .main.course {
    border: none;
    background-color: transparent;
    padding-top: 30px;
    border-radius: 20px;
    border: 5px solid rgba(85, 85, 85, 0);
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    margin: 80px auto 30px; }
    .page-estimate .main.course .inner.fixed {
      box-sizing: border-box;
      height: 670px; }

.animated-choice {
  width: 100%;
  height: 480px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  left: 0px;
  box-sizing: border-box; }
  .animated-choice .choice-area {
    height: 360px;
    width: 10000px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    padding-left: 180px; }
    .animated-choice .choice-area.selected0 {
      left: calc(-360px * 0);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected1 {
      left: calc(-360px * 1);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected2 {
      left: calc(-360px * 2);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected3 {
      left: calc(-360px * 3);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected4 {
      left: calc(-360px * 4);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected5 {
      left: calc(-360px * 5);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected6 {
      left: calc(-360px * 6);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected7 {
      left: calc(-360px * 7);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected8 {
      left: calc(-360px * 8);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected9 {
      left: calc(-360px * 9);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected10 {
      left: calc(-360px * 10);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected11 {
      left: calc(-360px * 11);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected12 {
      left: calc(-360px * 12);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected13 {
      left: calc(-360px * 13);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected14 {
      left: calc(-360px * 14);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected15 {
      left: calc(-360px * 15);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected16 {
      left: calc(-360px * 16);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected17 {
      left: calc(-360px * 17);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected18 {
      left: calc(-360px * 18);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected19 {
      left: calc(-360px * 19);
      transition: all 0.6s ease-out; }
    .animated-choice .choice-area.selected20 {
      left: calc(-360px * 20);
      transition: all 0.6s ease-out; }
  .animated-choice .choice-ctrl {
    position: absolute;
    left: 0px;
    top: 360px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 200px;
    margin-left: 255px; }
    .animated-choice .choice-ctrl button {
      cursor: pointer; }
    .animated-choice .choice-ctrl button.disabled {
      opacity: 0.5; }
  .animated-choice .choice-action {
    position: absolute;
    top: 410px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; }
    .animated-choice .choice-action button {
      cursor: pointer; }
    .animated-choice .choice-action button.disabled {
      opacity: 0.5; }
  .animated-choice .choiceWrap {
    width: 200px;
    max-width: none;
    margin-right: 160px;
    transition: all 0.6s ease-out; }
    .animated-choice .choiceWrap.selected {
      width: 355px;
      max-width: none;
      margin-right: 100px; }
  .animated-choice img.choice {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: all 0.6s ease-out; }

.action-button {
  height: 61.5px;
  width: 156px;
  background-size: 156px auto;
  cursor: pointer; }
  .action-button.return {
    background-image: url("/static/sozai/return.png"); }
  .action-button.no {
    background-image: url("/static/sozai/no.png"); }
  .action-button.choice {
    background-image: url("/static/sozai/choice.png"); }

#action-panel {
  height: 110px;
  width: 100%;
  background: #C4F0AC;
  position: fixed;
  bottom: 0; }
  #action-panel .inner {
    width: 720px;
    margin: 0 auto; }
    #action-panel .inner li.nav {
      display: inline-block;
      margin-top: 16px;
      margin-left: 10px;
      height: 56px;
      width: 120px;
      background-image: url("/static/sozai/footer_nav/109_nav1.png");
      background-position: 0px 56px;
      background-size: 120px 110px; }
      #action-panel .inner li.nav:first-child {
        margin-left: 32px; }
      #action-panel .inner li.nav.nav-base {
        background-image: url("/static/sozai/footer_nav/109_nav1.png"); }
        #action-panel .inner li.nav.nav-base.active {
          background-position: 0px 2px; }
      #action-panel .inner li.nav.nav-hei {
        background-image: url("/static/sozai/footer_nav/109_hei.png"); }
        #action-panel .inner li.nav.nav-hei.active {
          background-position: 0px 2px; }
      #action-panel .inner li.nav.nav-mon {
        background-image: url("/static/sozai/footer_nav/109_mon.png"); }
        #action-panel .inner li.nav.nav-mon.active {
          background-position: 0px 2px; }
      #action-panel .inner li.nav.nav-parking {
        background-image: url("/static/sozai/footer_nav/109_parking.png"); }
        #action-panel .inner li.nav.nav-parking.active {
          background-position: 0px 2px; }
      #action-panel .inner li.nav.nav-estimate {
        background-image: url("/static/sozai/footer_nav/109_nav5.png"); }
        #action-panel .inner li.nav.nav-estimate.active {
          background-position: 0px 2px; }

.course-cards .course-card {
  width: 312px;
  height: 332px;
  background: transparent;
  float: left;
  display: block;
  margin-left: 16px;
  margin-bottom: 15px;
  background-size: 312px 332px;
  position: relative; }
  .course-cards .course-card .button {
    position: absolute;
    bottom: 20px;
    left: 0px;
    height: 52.5px;
    width: 100%;
    background-image: url("/static/sozai/course/btn/521_105_start.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; }
    .course-cards .course-card .button.park_1 {
      background-image: url("/static/sozai/course/btn/246_106_1dai.png");
      background-position: 30px;
      width: 50%; }
    .course-cards .course-card .button.park_2 {
      background-image: url("/static/sozai/course/btn/246_106_2dai.png");
      background-position: 0px;
      margin-left: 50%;
      width: 50%; }
    .course-cards .course-card .button.mon_o {
      background-image: url("/static/sozai/course/btn/247_106_open.png");
      background-position: 30px;
      width: 50%; }
    .course-cards .course-card .button.mon_c {
      background-image: url("/static/sozai/course/btn/247_106_closeed.png");
      background-position: 0px;
      margin-left: 50%;
      width: 50%; }
  .course-cards .course-card.card01 {
    background-image: url("/static/sozai/course/panel/623_665_panel1.png"); }
  .course-cards .course-card.card02 {
    background-image: url("/static/sozai/course/panel/624_665_panel2.png"); }
  .course-cards .course-card.card03 {
    background-image: url("/static/sozai/course/panel/623_665_panel3.png"); }
  .course-cards .course-card.card04 {
    background-image: url("/static/sozai/course/panel/624_665_panel4.png"); }

.course-selector {
  display: block;
  margin: 0px;
  position: absolute;
  top: 110px;
  left: 0px;
  width: 720px; }
  .course-selector li {
    display: block;
    text-align: center;
    margin-top: 30px; }

.choice-selector-ctrl {
  position: absolute;
  top: 510px;
  left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%; }
  .choice-selector-ctrl button {
    cursor: pointer; }
  .choice-selector-ctrl button.disabled {
    opacity: 0.5; }

body.top {
  background: #FFFFE4;
  width: 100%;
  height: 100%; }

.top .wrap,
.top #only-first {
  display: none;
  position: relative;
  margin: auto;
  width: 720px; }
  .top .wrap .haikei,
  .top #only-first .haikei {
    position: absolute;
    top: 0px;
    z-index: 10;
    width: 720px; }
  .top .wrap .parts,
  .top #only-first .parts {
    position: absolute;
    top: 0px;
    z-index: 20; }
    .top .wrap .parts img,
    .top #only-first .parts img {
      width: 100%;
      height: 100%; }
    .top .wrap .parts .first,
    .top #only-first .parts .first {
      position: absolute;
      top: 0px;
      z-index: 11;
      width: 607px; }
    .top .wrap .parts.start.study,
    .top #only-first .parts.start.study {
      top: 855px;
      left: 48px;
      width: calc(620px*0.47); }
    .top .wrap .parts.start.simulation,
    .top #only-first .parts.start.simulation {
      top: 855px;
      right: 53px;
      width: calc(620px*0.47); }
    .top .wrap .parts.link,
    .top #only-first .parts.link {
      width: calc(263px*0.47);
      top: 510px;
      right: 50px; }
    .top .wrap .parts.const,
    .top #only-first .parts.const {
      width: calc(263px*0.47);
      top: 516px;
      left: 50px; }
    .top .wrap .parts.start.study_linkarea,
    .top #only-first .parts.start.study_linkarea {
      top: 772px;
      left: 40px;
      width: calc(660px*0.47);
      height: 124px;
      display: block; }
    .top .wrap .parts.start.study_linkarea a,
    .top #only-first .parts.start.study_linkarea a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .top .wrap .parts.start.simulation_linkarea,
    .top #only-first .parts.start.simulation_linkarea {
      top: 772px;
      right: 40px;
      width: calc(660px*0.47);
      height: 124px;
      display: block; }
    .top .wrap .parts.start.simulation_linkarea a,
    .top #only-first .parts.start.simulation_linkarea a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .top .wrap .parts.mazukoko,
    .top #only-first .parts.mazukoko {
      top: 715px; }
      .top .wrap .parts.mazukoko.mazukoko3,
      .top #only-first .parts.mazukoko.mazukoko3 {
        right: 42px;
        width: calc(533px*0.47); }
      .top .wrap .parts.mazukoko.mazukoko2,
      .top #only-first .parts.mazukoko.mazukoko2 {
        right: 303px;
        width: calc(379px*0.47); }
      .top .wrap .parts.mazukoko.mazukoko1,
      .top #only-first .parts.mazukoko.mazukoko1 {
        left: 40px;
        width: calc(405px*0.47); }

.inner.fixed .sub_title {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 720px;
  height: 100px;
  margin: 0px;
  background-position: top center;
  background-repeat: no-repeat; }
  .inner.fixed .sub_title.sub_title_sj {
    background-image: url("/static/sozai/base/sub_title_sj.png");
    background-size: auto calc(106px/2); }
  .inner.fixed .sub_title.sub_title_ti {
    background-image: url("/static/sozai/base/sub_title_ti.png");
    background-size: auto 25px; }
  .inner.fixed .sub_title.sub_title_dk {
    background-image: url("/static/sozai/base/sub_title_dk.png");
    background-size: auto calc(115px/2); }
  .inner.fixed .sub_title.sub_title_parking_1p {
    background-image: url("/static/sozai/parking/sub_title_parking_1p.png");
    background-size: auto 25px; }
  .inner.fixed .sub_title.sub_title_parking_2p {
    background-image: url("/static/sozai/parking/sub_title_parking_2p.png");
    background-size: auto 25px; }
  .inner.fixed .sub_title.sub_title_MCL {
    background-image: url("/static/sozai/mon/sub_title_MCL.png");
    background-size: auto 25px; }
  .inner.fixed .sub_title.sub_title_MOP {
    background-image: url("/static/sozai/mon/sub_title_MOP.png");
    background-size: auto 25px; }
  .inner.fixed .sub_title.sub_title_sk {
    background-image: url("/static/sozai/base/sub_title_sk.png");
    background-size: auto calc(115px/2);
    cursor: pointer; }

.logo-lixil {
  margin-bottom: 40px; }

.modal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2); }
  .modal img {
    margin: 20vh auto 0px auto;
    width: 515px;
    display: block; }

#action-panel .inner li.nav {
  margin-top: 10px; }

#action-panel .inner ul {
  margin: 12px 0 0; }

#action-panel .inner p.copyright {
  text-align: center;
  font-size: 70%;
  color: #666; }

#top-copyright {
  line-height: 1.7;
  margin-top: 0;
  top: 930px;
  left: 0px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 70%; }

.wrap-info.caution p {
  font-size: 80% !important;
  margin: 0;
  padding: 0; }

.top #only-first {
  display: block;
  z-index: 100; }

.top .wrap {
  display: block; }

#top_pc {
  display: none; }

#top_sp {
  display: none; }

@media screen and (max-width: 999px) {
  #top_sp {
    display: block; } }

@media screen and (min-width: 1000px) {
  body.top {
    margin: 0;
    padding: 0;
    background: #ffffe4 url("/static/sozai/top_pc/background.jpg") center !important; }
  #top_pc {
    width: 1335px;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    display: block; }
  #top_pc img {
    position: absolute; }
  #top_pc .logo {
    top: 10px;
    left: 391px; }
  #top_pc .niwa {
    top: 200px;
    left: 404px; }
  #top_pc .btn_hajimete {
    top: 640px;
    left: 603px; }
  #top_pc .ttl_about {
    top: 143px;
    left: 0; }
  #top_pc .btn_about_1 {
    top: 216px;
    left: 0; }
  #top_pc .btn_about_2 {
    top: 280px;
    left: 0; }
  #top_pc .btn_about_3 {
    top: 344px;
    left: 0; }
  #top_pc .btn_link {
    top: 550px;
    left: 0; }
  #only-first2 img {
    position: absolute;
    top: 20px;
    left: 342px;
    z-index: 100;
    opacity: 0.95; }
  #top_pc .btn_study {
    top: 216px;
    left: 1082px; }
  #top_pc .btn_simulation {
    top: 465px;
    left: 1082px; }
  #top_pc .btn_const {
    top: 430px;
    left: 0; }
  #top_pc .notice {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 700px; }
  #top_pc .copyright {
    position: fixed;
    bottom: 0;
    width: 1335px;
    height: 25px;
    background-color: #ffab2c;
    padding-top: 3px; } }
